import AOS from 'aos';
import vhCheck from 'vh-check/dist/vh-check';
import { debounce } from './front/utilities/utilities';
import "./front/components/header/lifecycle";
import "./front/components/dropdown/lifecycle";
import "./front/components/slider/lifecycle";
import "./front/components/contact/lifecycle";
import "./front/components/newsletter/lifecycle";
import "./front/components/footer/lifecycle";
import "./front/components/popin/lifecycle";
import "./front/components/slider-header/lifecycle";

const check = () => {
  const test = vhCheck();
  document.documentElement.style.setProperty('--vh-offset', `${test.value}px`);
};

document.addEventListener('DOMContentLoaded', () => {
  check();
  window.addEventListener('resize', debounce(check));

  // AOS
  const animatiedElements = document.querySelectorAll('[data-aos]');
  let reducedMotion = 0;
  reducedMotion = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--reduced-motion'), 10) === 1;

  if(animatiedElements.length > 0 && !reducedMotion) {
    AOS.init({
      duration: 800,
      once: true
    });
  }
});
