import 'mdn-polyfills/NodeList.prototype.forEach';
import Slider from './Slider';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.js-slideshow');
    if (!sliders.length) {
      return;
    }
    sliders.forEach((element) => {
      const slider = new Slider(element);

        slider.mount();

    });
});
