import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isNaN';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.swiperElement = this.element.querySelector('.swiper');
    //this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.prev = this.element.querySelector('.swiper-button-prev');
    this.next = this.element.querySelector('.swiper-button-next');
    this.pagination = this.element.querySelector('.swiper-pagination');
    this.sliderLength = this.element.getAttribute("data-length");
    this.settings = {
      navigation: {
        nextEl: this.next,
        prevEl: this.prev
      },
      pagination: {
        el: this.pagination,
        clickable: true,
        type: 'bullets',
      },
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
    };
    this.swiper;
  }

  mount() {
    Swiper.use([Autoplay, Navigation, Pagination]);

      this.settings = {
        ...this.settings,
      }

    console.log(this.sliderLength);

    if(this.sliderLength!="1"){
      this.swiper = new Swiper(this.swiperElement, this.settings);
    }else {
    }

  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
